<template>
  <router-view/>
</template>

<script>

// import AppLoginView from '@/views/AppLoginView.vue';

export default {
  name: 'App',
  
}
</script>
