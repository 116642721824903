import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex';

const router = createRouter({
  history: createWebHistory(),
  routes: [
  {
    path: '/',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppLoginView.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppIndexView.vue')
  },
  {
    path: '/cadastro_menu',
    name: 'cadastro_menu',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppCadastroMenuView.vue')
  },
  {
    path: '/cadastro_dicionario_telas',
    name: 'cadastro_dicionario_telas',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppCadastroDicionarioTelasView.vue')
  },
  {
    path: '/cadastro_dicionario_telas/incluir',
    name: 'cadastro_dicionario_telas_incluir',
    meta: {
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppCadastroDicionarioTelasView.vue')
  },
  {
    path: '/:module(.*)',
    name: 'module_param',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppIndexView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/:module(.*)/incluir',
    name: 'module_param_incluir',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppIndexView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/:module(.*)/edicao/:id',
    name: 'module_param_edicao',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/AppIndexView.vue'),
    meta: {
      auth: true
    }
  }
  ]
})

router.beforeEach((to, from, next) => {
  
  const store = useStore()
  

  if (to.meta.auth)
  {
    
    store.dispatch('getLogin').then(() => {  
      if (store.state.login && store.state.login.token)
      {
        
          
          store.dispatch('doVerifyLogin', store.state.login.token).then((data) => {
            if (data.status != 200)
            {
              next({ name: "login" })
            }else {
              next()
            }
          })
          
        
      }else{
        next({ name: "login" })
      }
    })
    
  }else{
    next()
  }
  
})

export default router
