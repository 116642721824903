import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    login: null,
    dicionario: null,
    dicionarioModal: null,
    empresas: [],
    empresaSelecionada: null,
    menu: [],
    menuCadastro: [],
    menuDicionarioTela: [],
    crudData:[],
    customData:[],
    validadores:[],
    tabelas:[],
    exception: null
  },
  mutations: {
    
    storeException(state, payload)
    {
      localStorage.setItem('exception', JSON.stringify(payload))
      state.exception = payload
    },
    removeException(state)
    {
      state.exception = null
      localStorage.removeItem('exception')
    },
    storeToastData(state, toastData)
    {
      localStorage.removeItem('toastData')
      state.toastData = null
      state.toastData = toastData
      localStorage.setItem('toastData', JSON.stringify(toastData))
    },

    removeToastData(state)
    {
      localStorage.removeItem('toastData')
      state.toastData = null
    },

    removeAllStore(state)
    {
      localStorage.removeItem('login')
      localStorage.removeItem('dicionario')
      localStorage.removeItem('dicionarioModal')
      localStorage.removeItem('empresaSelecionada')
      localStorage.removeItem('exception')
      state.login = null
      state.dicionario = null
      state.dicionarioModal = null
      state.empresaSelecionada = null
      state.exception = null
    },

    storeLogin(state, payload)
    {
      localStorage.setItem('login', JSON.stringify(payload))
      state.login = JSON.parse(localStorage.getItem('login'))

    },
    getLogin(state)
    {
      state.login = JSON.parse(localStorage.getItem('login'))
    },
    storeDicionario(state, payload)
    {
      localStorage.setItem('dicionario', JSON.stringify(payload))
      state.dicionario = JSON.parse(localStorage.getItem('dicionario'))
    },
    getDicionario(state)
    {
      state.dicionario = JSON.parse(localStorage.getItem('dicionario'))
    },
    removeDicionario(state)
    {
      state.dicionario = null
      localStorage.removeItem('dicionario')
    },
    storeDicionarioModal(state, payload)
    {
      localStorage.setItem('dicionarioModal', JSON.stringify(payload))
      state.dicionarioModal = JSON.parse(localStorage.getItem('dicionarioModal'))
    },
    getDicionarioModal(state)
    {
      state.dicionarioModal = JSON.parse(localStorage.getItem('dicionarioModal'))
    },
    removeDicionarioModal(state)
    {
      state.dicionarioModal = null
      localStorage.removeItem('dicionarioModal')
    },
    storeEmpresas(state, payload)
    {
      state.empresas = payload
    },
    storeMenu(state, payload)
    {
      state.menu = payload
    },
    storeMenuCadastro(state, payload)
    {
      state.menuCadastro = payload
    },
    storeMenusDicionarioTela(state, payload)
    {
      state.menuDicionarioTela = payload
    },
    storeEmpresaSelecionada(state, payload)
    {
      localStorage.setItem('empresaSelecionada', JSON.stringify(payload))
      state.empresaSelecionada = JSON.parse(localStorage.getItem('empresaSelecionada'))
    },
    getEmpresaSelecionada(state)
    {
      state.empresaSelecionada = JSON.parse(localStorage.getItem('empresaSelecionada'))
    },
    crudData(state, payload)
    {
      state.crudData = payload
    },
    customData(state, payload)
    {
      state.customData = payload
    },
    storeValidadores(state, payload)
    {
      state.validadores = payload
    },
    storeTabelas(state, payload)
    {
      state.tabelas = payload
    }
  },
  getters: {
    
  },
  actions: {
    removeException({commit})
    {
      return new Promise((resolve) => {
        commit('removeException')
        resolve();
      })
    },

    doLogin({commit}, data)
    {
      return new Promise((resolve) => {
        return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/login`, data).then((response) => {
          commit('storeLogin', response.data)
          resolve();
        }).catch((error) => {
          resolve(error.response.data.mensagem);
        })
      })
    },
    
    getLogin({commit})
    {
      return new Promise((resolve) => {
          commit('getLogin')
          resolve();
      })
      
    },
    doLogout({commit}, token)
    {
      return new Promise((resolve) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
        return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/logout`, null, {headers: headers}).then(() => {
          commit('removeAllStore')
          resolve();
        })
      })
      
    },
    doVerifyLogin({commit}, token)
    {
      return new Promise((resolve) => {
        
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
        
        
        
        return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/verify`, null, {headers: headers}).then((response) => {
          resolve(response);
        }).catch((error) => {
          commit('removeAllStore')
          resolve(error.response);
        })
      })
    },

    doDicionario({commit}, {menu_id, token})
    {
      return new Promise((resolve) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
        const request = {
          menu_id: menu_id
        }
        
        return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/dicionario/carregaDicionarioPorMenu`, request, {headers: headers}).then((response) => {
          commit('storeDicionario', response.data.data)
          resolve();
        })
      })
    },
    getDicionario({commit})
    {
      return new Promise((resolve) => {
          commit('getDicionario')
          resolve();
      })
    },
    removeDicionario({commit})
    {
      return new Promise((resolve) => {
        
        commit('removeDicionario')
        resolve();
        
      })
    },
    doDicionarioModal({commit}, {menu_id, token})
    {
      return new Promise((resolve) => {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
        const request = {
          menu_id: menu_id
        }
        
        return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/dicionario/carregaDicionarioPorMenu`, request, {headers: headers}).then((response) => {
          commit('storeDicionarioModal', response.data.data)
          resolve();
        })
      })
    },
    getDicionarioModal({commit})
    {
      return new Promise((resolve) => {
          commit('getDicionarioModal')
          resolve();
      })
    },
    removeDicionarioModal({commit})
    {
      return new Promise((resolve) => {
        
        commit('removeDicionarioModal')
        resolve();
        
      })
    },
    listarDicionario({commit}, {form, page, token})
    {

      const request = {
        form
      }

      return new Promise((resolve) => {   
        if (token)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/dicionario/listarDicionario?page=${page}`, request, {headers: headers}).then((response) => {
            commit('customData' ,{status: response.status, data: response.data})
            resolve();
          }).catch(error => {
            commit('customData' ,{status: error.response.status, data: error.response.data})
            resolve();
          })
        }
      })
    },
    salvaDicionario({commit}, {telas, token})
    {

      const request = {
        telas
      }

      return new Promise((resolve) => {   
        if (token)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/dicionario/criaDicionario`, request, {headers: headers}).then((response) => {
            commit('customData' ,{status: response.status, data: response.data})
            resolve();
          }).catch(error => {
            commit('customData' ,{status: error.response.status, data: error.response.data})
            resolve();
          })
        }
      })
    },
    loadValidadores({commit}, token)
    {
      return new Promise((resolve) => {   
        if (token)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/dicionario/carregaValidadores`, null, {headers: headers}).then((response) => {
            const data = {
              token: token,
              data: response.data.data
            }
            commit('storeValidadores', data)
            resolve();
          })
        }
      })
    },
    loadTabelas({commit}, token)
    {
      return new Promise((resolve) => {   
        if (token)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/dicionario/carregaTabelas`, null, {headers: headers}).then((response) => {
            const data = {
              token: token,
              data: response.data.data
            }
            commit('storeTabelas', data)
            resolve();
          })
        }
      })
    },
    loadEmpresas({commit}, login)
    {
      return new Promise((resolve) => {   
        if (login)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${login.token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/empresa/carregaEmpresas`, null, {headers: headers}).then((response) => {
            const data = {
              token: login.token,
              empresas: response.data.data
            }
            commit('storeEmpresas', data)
            resolve();
          })
        }
      })
    },
    loadMenu({commit}, login)
    {
      return new Promise((resolve) => {   
        if (login)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${login.token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/menu/carregaMenus`, null, {headers: headers}).then((response) => {
            const data = {
              token: login.token,
              menu: response.data.data
            }
            commit('storeMenu', data)
            resolve();
          })
        }
      })
    },
    salvaMenu({commit}, {form, token}){

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }

      const request = {
        form
      }
    
      return new Promise((resolve) => {
        
        return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/menu/salvarMenus`, request, {headers: headers})
          .then((response) => {
            commit('customData' ,{status: response.status, data: response.data})
            resolve();
          }).catch(error => {
            commit('customData' ,{status: error.response.status, data: error.response.data})
            resolve();
          })
      })
    },
    loadMenuCadastro({commit}, login)
    {
      return new Promise((resolve) => {   
        if (login)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${login.token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/menu/carregaMenusCadatro`, null, {headers: headers}).then((response) => {
            const data = {
              token: login.token,
              menu: response.data.data
            }
            commit('storeMenuCadastro', data)
            resolve();
          })
        }
      })
    },
    loadMenusDicionarioTela({commit}, token)
    {
      return new Promise((resolve) => {   
        if (token)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/menu/carregaMenusDicionarioTela`, null, {headers: headers}).then((response) => {
            const data = {
              token: token,
              menu: response.data.data
            }
            commit('storeMenusDicionarioTela', data)
            resolve();
          })
        }
      })
    },
    storeEmpresaSelecionada({commit}, {empresa_id, token})
    {
      return new Promise((resolve) => {   
        if (token)
        {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
          const request = {
            empresa_id: empresa_id
          }
          
          return axios.post(`${process.env.VUE_APP_URL_ENDPOINT_SISTEMA}/empresa/selecionaEmpresa`, request, {headers: headers}).then((response) => {
            const data = {
              token: token,
              empresa: response.data.data
            }
            commit('storeEmpresaSelecionada', data)
            resolve();
          })
        }
      })
    },
    getEmpresaSelecionada({commit})
    {
      return new Promise((resolve) => {
        commit('getEmpresaSelecionada')
        resolve();      
      })
    },
    crud({commit}, {menu_id, endpoint, metodo, page, form, token})
    {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }

      const request = {
        menu_id: menu_id,  
        form
      }
      
      if (page)
      {
        endpoint =  endpoint + `?page=${page}`
      }
      
      return new Promise((resolve) => {
        
        return axios(
          {
            baseURL: process.env.VUE_APP_URL_ENDPOINT_SISTEMA,
            url: endpoint,
            method: metodo,
            data: request,
            headers: headers
          }).then((response) => {
            commit('crudData' ,{status: response.status, data: response.data})
            resolve();
          }).catch(error => {
            commit('crudData' ,{status: error.response.status, data: error.response.data})
            resolve();
          })
      })
    },
    storeToastData({commit}, toastData)
    {
      return new Promise((resolve) => {
        commit('storeToastData', toastData)
        resolve();
      })
    },
    removeToastData({commit})
    {
      return new Promise((resolve) => {
        commit('removeToastData')
        resolve();
      })
    },
  },
  modules: {
  }
})
